import request from '@/utils/request';

const PATH = '/apin/v2/groups';

export const addGroup = (data = {}) => request({
  url: PATH,
  method: 'POST',
  data,
});

export const deleteGroup = (params: { groupId: number }) => request({
  url: `${PATH}/${params.groupId}`,
  method: 'DELETE',
});

// 글감 게시글 목록
export const getV2SubjectPostList = (groupId: any, activityId: any, subjectId: any) => request({
  url: `${PATH}/${groupId}/activities/${activityId}/subjects/${subjectId}/posts`,
  method: 'GET',
});

// 글감 피드백 목록
export const getV2SubjectFeedBackList = (groupId: any, activityId: any, subjectId: any) => request({
  url: `${PATH}/${groupId}/activities/${activityId}/subjects/${subjectId}/feedbacks`,
  method: 'GET',
});

// 글감 피드백 평가 기준 목록
export const getV2SubjectFeedBackCriteriaList = (groupId: any, activityId: any, subjectId: any) => request({
  url: `${PATH}/${groupId}/activities/${activityId}/subjects/${subjectId}/criteria`,
  method: 'GET',
});

// 그룹 내 사용자 목록 조회
export const getV2GroupUserList = (groupId: any) => request({
  url: `${PATH}/${groupId}/users`,
  method: 'GET',
});

export const getV2GroupInfo = (groupId: string | number) => request({
  url: `${PATH}/${groupId}`,
  method: 'GET',
});
